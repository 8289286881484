<template>
  <v-btn @click="cancel">

    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>

    <slot v-if="!loading">
      Cancel Transfer
    </slot>

  </v-btn>
</template>

<script>

  import { cancelTransfer } from '@/services/http/license_transfers'

  export default {
    name: 'CancelLicenseTransfer',
    props: ['transferId'],
    data: () => ({
      loading: false,
    }),
    methods: {
      async cancel() {
        const yn = await this.$confirm('Are you sure you want to cancel license transfer?', {title: 'Confirm'})
        if (yn) {
          this.loading = true
          try {
            await cancelTransfer(this.transferId)
            this.$emit('cancelled')
          } catch(e) {
            this.error = e
          } finally {
            this.loading = false
          }
        }
      }
    }
  }
</script>
