<template>
  <dashboard-layout title="Manage License">
    <template slot="content">
      <v-container v-if="load_error">
        <v-alert type="error">
          {{ load_error }}
        </v-alert>
      </v-container>
      <loading-circular v-if="loading"></loading-circular>
      <v-container v-if="!loading && !load_error">
        <v-row>
          <v-col cols="12" v-if="pk.license_transfer">
            <v-card color="orange">
              <v-card-title>
                Pending License Transfer
              </v-card-title>
              <v-card-text>
                <p>
                  <strong v-if="!pk.license_transfer.confirmed_at">
                    You must click the confirmation link we sent to your email first before the recipient can see the license transfer offer.
                  </strong>

                  <strong v-if="pk.license_transfer.confirmed_at">
                    License transfer offer is now ready to be accepted by the recipient.
                  </strong>
                </p>
                <p>
                  Recepient: {{ pk.license_transfer.user.email }}
                </p>
              </v-card-text>
              <v-card-actions>
                <cancel-license-transfer :transfer-id="pk.license_transfer.id" @cancelled="transferCancelled">
                  Cancel Transfer
                </cancel-license-transfer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                License Key
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-chip
                        label
                        class="my-2"
                      >
                        {{pk.code}}
                      </v-chip>
                      <v-btn
                        elevation="1"
                        small
                        color="primary"
                        v-clipboard="pk.code"
                        class="my-2"
                      >
                        Copy License Key
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                Information
              </v-card-title>
              <v-card-text>
                <p>
                  Type: {{ pk.sku_str.toUpperCase() }}
                </p>
                <p>
                  Owner: {{pk.user.email}}
                </p>
                <p v-if="pk.device">
                  Machine:
                  <router-link :to="{name: 'Machine', params: {id: pk.device.id}}">
                    {{ pk.device.alias || pk.device.cid }}
                  </router-link>
                </p>
                <p v-if="pk.available">
                  Status: <span class="green--text">Available</span>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="!pk.license_transfer">
            <v-card>
              <v-card-title>
                Transfer License
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid">
                  <v-text-field
                    label="Type the email of recepient"
                    v-model="email"
                    :rules="emailRules"
                    :disabled="transfering"
                    :loading="transfering"
                    :error-messages="transfer_error"
                    type="email"
                    required
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    color="orange"
                    :disabled="!valid"
                    :loading="transfering"
                    @click.prevent="doTransfer"
                  >
                    Transfer
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title>Notes</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="update">
                  <v-textarea placeholder="Add notes here..." v-model="pk.note">
                  </v-textarea>
                  <v-btn type="submit"
                  color="primary"
                >
                    Save
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </dashboard-layout>
</template>
<script>
  import CancelLicenseTransfer from '@/views/dashboard/license/CancelLicenseTransfer'
  import { getProductKey, updateProductKey } from '@/services/http/product_keys'
  import { transferLicense } from '@/services/http/license_transfers'

  export default {
    name: 'LicensePage',
    components: {
      CancelLicenseTransfer
    },
    data() {
      return {
        valid: true,
        loading: true,
        transfering: false,
        pk: {},
        load_error: null,
        transfer_error: null,

        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    },
    created() {
      this.load()
    },
    methods: {
      async load() {
        try {
          this.pk = await getProductKey(this.$route.params.id)
        } catch(e) {
          this.error = e
        }
        this.loading = false
      },
      async update() {
        try {
          this.pk = await updateProductKey(this.pk.id, this.pk)
          this.$toast('Saved successfully.')
        } catch(e) {
          this.$toast(e.toString())
        }
      },
      transferCancelled() {
        this.$toast('Cancelled license transfer.')
        this.pk.license_transfer = null
      },
      async doTransfer() {
        this.transfering = true
        this.transfer_error = null
        try {
          const { data } = await transferLicense(this.pk.id, this.email)
          this.email = null
          if (data.license_transfer && data.license_transfer.confirmed_at) {
            this.$toast('The recipient can now see the offer, no need to confirm via email.')
          } else {
            this.$toast('Please check your email for confirmation link.')
          }
          this.load()
        } catch(e) {
          this.transfer_error = e.toString()
        } finally {
          this.transfering = false
        }
      }
    },
    watch: {
      email(email) {
        if (email)
          this.transfer_error = null
      }
    }
  }
</script>
